import mentor1 from "../images/mentors/4.png";
import mentor2 from "../images/mentors/5.png";
import mentor3 from "../images/mentors/6.png";

const mentors = [{
        id: '1',
        title: 'BHUPENDRA KOTWAL',
        screens: mentor1,
        category: 'President',
    },
    {
        id: '2',
        title: 'NARENDAR GEHLOT',
        screens: mentor2,
        category: 'Secretary',
    },
    {
        id: '3',
        title: 'UMESH TULSYAN',
        screens: mentor3,
        category: 'Treasurer',
    },

];
export default mentors;