import React, { useState } from 'react';
import axios from "axios";
import Footer from '../footer/Footer';
import "./register.scss";
import { useNavigate } from 'react-router-dom';
import Nav_Bar from '../Nav_Bar';
import Load from '../Load';
import './register.css'
import { TextField,MenuItem } from '@material-ui/core';

const feedback_subject = [
    {
        value: "Suggestion",
        label: "Suggestion"
    },
    {
        value: "Feedback",
        label: "Feedback"
    },
    {
        value: "Complaint",
        label: "Complaint"
    },
]

const Feedback = (props) => {
    const navigate = useNavigate();
    const styles = {
        top: {
            marginBottom: '320px'
        },
    };
    
  

    const [forms, setForms] = useState({
        feedback_name: '',
        feedback_subject: '',
        feedback_mobile: '',
        feedback_description: '',
        
    });

    const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);

    const validateOnlyDigits = (inputtxt) => {
        var phoneno = /^\d+$/;
        if (inputtxt.match(phoneno) || inputtxt.length == 0) {
            return true;
        } else {
            return false;
        }
    }

    
    const validateOnlyText = (inputtxt) => {

        var re = /^[A-Za-z ]+$/;
        if (inputtxt === "" || re.test(inputtxt)) {
            return true;
        } else {
            return false;
        }
    }

    const onInputChange = (e) => {

        if (e.target.name == "feedback_mobile") {

            if (validateOnlyDigits(e.target.value)) {
                setForms({
                    ...forms,
                    [e.target.name]: e.target.value,
                });
            }

        } else if (e.target.name == "feedback_name") {

            if (validateOnlyText(e.target.value)) {
                setForms({
                    ...forms,
                    [e.target.name]: e.target.value,
                });
            }

        }  else {

            setForms({
                ...forms,
                [e.target.name]: e.target.value,
            });
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        var v = document.getElementById("addcheck").checkValidity();
        var v = document.getElementById("addcheck").reportValidity();
        if (v) {
            setIsButtonDisabled(true)
            let headers = new Headers();
            const data = new FormData();
            data.append("feedback_name", forms.feedback_name);
            data.append("feedback_subject", forms.feedback_subject);
            data.append("feedback_mobile", forms.feedback_mobile);
            data.append("feedback_description", forms.feedback_description);
            
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('Origin', 'http://localhost:3000');
            headers.append('Access-Control-Allow-Origin', '*');
            axios({
                url: "http://businessboosters.club/public/api/createFeedbackNew",
                method: "POST",
                data,
                headers: headers,
            }).then((res) => {

                if (res.data.code == '200') {
                    setTimeout(() => {
                        navigate('/thankyouf')
                    }, 1)
                } else {
                    setTimeout(() => {
                        navigate('/failuref')
                    }, 1)
                }


            });
        }
    };

    return (
        <>
            <Nav_Bar />
            <Load/>
            <section className={`formregister wpo-contact-area section-padding ${props.contactclass}`} id="register" style={styles.top}>
                <div className="wpo-wpo-contact-form-map">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="wpo-section-title">
                                    <h2>Feedback</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="co-lg-12   col-12">
                                <div className="row">
                                    <div className="col col-lg-12 col-md-12 col-12">
                                        <div className="wpo-contact-form">
                                            <form id="addcheck">
                                                <div className='row'>
                                                    <div className="col col-lg-4 col-12">
                                                        <div className="form-field">
                                                            <TextField
                                                                style={{ color: 'Black' }}
                                                                required
                                                                fullWidth
                                                                label="Full Name"
                                                                value={forms.feedback_name}
                                                                name="feedback_name"
                                                                onChange={(e) => onInputChange(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col col-lg-4 col-12">
                                                        <div className="form-field">
                                                            <TextField
                                                                style={{ color: 'Black' }}
                                                                fullWidth
                                                                label="Mobile No"
                                                                required
                                                                inputProps={{ minLength: 10, maxLength: 10 } }
                                                                value={forms.feedback_mobile}
                                                                type="text"
                                                                name="feedback_mobile"
                                                                onChange={(e) => onInputChange(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col col-lg-4 col-12">
                                                        <div className="form-field">
                                                            <TextField
                                                                style={{ color: 'Black', height: '50px' }}
                                                                required
                                                                fullWidth
                                                                label="Subject"
                                                                value={forms.feedback_subject}
                                                                inputProps={{ maxLength: 10 }}
                                                                select
                                                                SelectProps={{
                                                                    MenuProps: {},
                                                                }}
                                                                name="feedback_subject"
                                                                onChange={(e) => onInputChange(e)}>
                                                                {
                                                                    feedback_subject.map((option) => (
                                                                        <MenuItem key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </TextField>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col col-lg-12 col-12">
                                                        <div className="form-field">
                                                            <TextField
                                                                label="Description"
                                                                required
                                                                fullWidth
                                                                style={{ color: 'Black' }}
                                                                value={forms.feedback_description}
                                                                name="feedback_description"
                                                                onChange={(e) => onInputChange(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="submit-area">
                                                    <button type="submit" className="theme-btn" onClick={(e) => onSubmit(e)}  style={{ width: '200px', backgroundColor: '#A41460', borderRadius: '50px', fontWeight: '600', color: 'white', padding: '13px' }}>
                                                    Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shape-wk">
                    <svg width="1500" height="1500" viewBox="0 0 1500 1500" fill="none">
                        <g opacity="0.45" filter="url(#filter0_f_39_4214)">
                            <circle cx="750" cy="750" r="200" />
                        </g>
                        <defs>
                            <filter id="filter0_f_39_4214" x="0" y="0" width="1500" height="1500"
                                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feGaussianBlur stdDeviation="275" result="effect1_foregroundBlur_39_4212" />
                            </filter>
                        </defs>
                    </svg>
                </div>
            </section>
            <Footer />
            
        </>
    )
}
export default Feedback;